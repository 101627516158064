import parasets from "./parasets.json"

export type ParaSet = Set<string>

export const sets = Object.fromEntries(
    Object.entries(parasets).map(([setSymbol, { tokenIds }]) => [
        setSymbol,
        tokenIds.map((tokenId) => tokenId.toString())
    ])
)
