import { action, Action, Computed, computed } from "easy-peasy"

export type Currency = "ETH" | "WETH" | "USDC"
export interface StoreModel {
    ethInUsd: number | undefined
    setEthInUsd: Action<StoreModel, number | undefined>

    cardsBalances: Record<string, { wallet: number; vault: number }> | undefined
    totalCardsBalances: Computed<StoreModel, Record<string, number> | undefined>
    setCardsBalances: Action<
        StoreModel,
        Record<string, { wallet: number; vault: number }> | undefined
    >
    cardsPrices:
        | Record<string, { currency: Currency; value: number }>
        | undefined
    setCardsPrices: Action<
        StoreModel,
        Record<string, { currency: Currency; value: number }> | undefined
    >
}

const storeModel: StoreModel = {
    ethInUsd: undefined,
    setEthInUsd: action((store, ethInUsd) => {
        store.ethInUsd = ethInUsd
    }),

    cardsBalances: undefined,
    totalCardsBalances: computed(
        [(state) => state.cardsBalances],
        (cardsBalances) => {
            if (!cardsBalances) return cardsBalances
            const totalCardsBalances: Record<string, number> = {}
            Object.keys(cardsBalances).forEach(
                (tokenId) =>
                    (totalCardsBalances[tokenId] =
                        cardsBalances[tokenId].wallet +
                        cardsBalances[tokenId].vault)
            )
            return totalCardsBalances
        }
    ),
    setCardsBalances: action((store, cardsBalances) => {
        store.cardsBalances = cardsBalances
    }),
    cardsPrices: undefined,
    setCardsPrices: action((store, cardsPrices) => {
        store.cardsPrices = cardsPrices
    })
}

export default storeModel
