import { StoreProvider } from "easy-peasy"
import SetCheckerPage from "pages/SetCheckerPage"
import { useEffect } from "react"
import { BrowserRouter as Router } from "react-router-dom"

import { useStoreActions, useStoreState } from "state/hooks"
import store from "state/store"

import { sets } from "data/sets"
import { getCardsPrices } from "data/utils"

import Footer from "components/Footer"
import Navbar from "components/Navbar"
import PageContentContainer from "components/PageContentContainer"

const EthPriceLoader = () => {
    const ethInUsd = useStoreState((state) => state.ethInUsd)
    const setEthInUsd = useStoreActions((actions) => actions.setEthInUsd)
    useEffect(() => {
        const loadEthInUsd = async () => {
            const ethInUsd = (
                await (
                    await fetch(
                        "https://api.coingecko.com/api/v3/simple/price?ids=ethereum&vs_currencies=usd"
                    )
                ).json()
            )["ethereum"]["usd"] as number
            setEthInUsd(ethInUsd)
        }
        if (ethInUsd === undefined) {
            loadEthInUsd().catch(console.error)
        }
    }, []) //eslint-disable-line react-hooks/exhaustive-deps
    return null
}

const CardsPricesLoader = () => {
    const cardsPrices = useStoreState((state) => state.cardsPrices)
    const setCardsPrices = useStoreActions((actions) => actions.setCardsPrices)
    useEffect(() => {
        const loadPrices = async () => {
            const cardsInSets = Object.values(sets)
                .map((set) => Array.from(set))
                .reduce((a, b) => [...a, ...b])
            const prices = await getCardsPrices(cardsInSets)
            setCardsPrices(prices)
        }
        if (cardsPrices === undefined) {
            loadPrices().catch(console.error)
        }
    }, []) //eslint-disable-line react-hooks/exhaustive-deps
    return null
}

const App = () => {
    return (
        <StoreProvider store={store}>
            <Router>
                <EthPriceLoader />
                <CardsPricesLoader />
                <div className="flex flex-col items-center w-full max-w-4xl mx-auto">
                    <Navbar />
                    <PageContentContainer>
                        <SetCheckerPage />
                        {/*
                        <Switch>
                            <Route
                                path="/"
                                exact
                                component={() => <Redirect to="/parasets" />}
                            />
                            <Route
                                path="/parasets"
                                exact
                                component={SetCheckerPage}
                            />
                            <Route
                                path="/cardgen"
                                exact
                                component={CardGeneratorPage}
                            />
                        </Switch>
                        */}
                    </PageContentContainer>
                </div>
                <Footer />
            </Router>
        </StoreProvider>
    )
}

export default App
