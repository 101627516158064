import { Provider } from "@ethersproject/abstract-provider"
import { BigNumber } from "ethers"
import { formatUnits } from "ethers/lib/utils"
import { RateLimiter } from "limiter"

import { Currency } from "state/models/StoreModel"

import { ParasetBalance } from "data/types"

import { NOT_AVAILABLE_ERROR } from "components/setchecker/errors"

import {
    ParallelTokenAbi__factory,
    ParasetStakingAbi__factory
} from "./abi/types"
import parasets from "./parasets.json"

export const PARALLEL_TOKEN_ADDRESS =
    "0x76be3b62873462d2142405439777e971754e8e77"
export const PARASET_STAKING_ADDRESS =
    "0xECa9D81a4dC7119A40481CFF4e7E24DD0aaF56bD"
export const PARASET_SNAPSHOT_TIMESTAMP = 1630011600

const MAX_CARDS_PER_BATCH = 30
const MAX_CARDS_PER_OPENSEA_REQUEST = 30

export const getCachedParasets = async (
    account: string,
    provider: Provider
) => {
    const parasetStaking = ParasetStakingAbi__factory.connect(
        PARASET_STAKING_ADDRESS,
        provider
    )
    return await Promise.all(
        Object.entries(parasets).map((paraset) =>
            parasetStaking
                .cacheInfo(paraset[1].pid, account)
                .then(({ amount }) => ({
                    setName: paraset[0],
                    amount: amount.toNumber(),
                    paraset: paraset[1]
                }))
        )
    )
}

export const getCachedCardsBalance = async (parasets: ParasetBalance[]) => {
    const cardsBalances: Record<number, number> = {}
    parasets.forEach(({ amount, paraset }) =>
        paraset.tokenIds.forEach((tokenId) => (cardsBalances[tokenId] = amount))
    )
    return cardsBalances
}

export const getCardsBalancesBatch = (
    account: string,
    tokenIds: string[],
    provider: Provider
) => {
    const token = ParallelTokenAbi__factory.connect(
        PARALLEL_TOKEN_ADDRESS,
        provider
    )
    return token.balanceOfBatch(
        tokenIds.map((_) => account),
        tokenIds
    )
}

export const getCardsBalances = async (
    account: string,
    tokenIds: string[],
    provider: Provider
) => {
    const batches: string[][] = []
    while (tokenIds.length > 0) {
        batches.push(tokenIds.splice(0, MAX_CARDS_PER_BATCH))
    }
    const result: BigNumber[] = []
    return result
        .concat(
            ...(await Promise.all(
                batches.map((batch) =>
                    getCardsBalancesBatch(account, batch, provider)
                )
            ))
        )
        .map((b) => b.toNumber())
}

export const getCardsBalancesInVault = async (
    account: string,
    tokenIds: string[]
) => {
    const corsPrefix =
        process.env.NODE_ENV === "development"
            ? "https://thingproxy.freeboard.io/fetch/"
            : ""
    const response = await fetch(
        corsPrefix + "https://parallel.life/api/v1/mycards/",
        {
            headers: { "X-Current-Eth-Address": account }
        }
    )
    if (!response.ok && response.status === 400) {
        throw new Error(NOT_AVAILABLE_ERROR)
    }
    const json: { card: { token_id: number }; vault_quantity: number }[] =
        await response.json()
    const balanceByTokenId: Record<string, number> = {}
    json.forEach(
        ({ card, vault_quantity }) =>
            (balanceByTokenId[card.token_id] = vault_quantity)
    )
    const result = tokenIds.map((tokenId) => balanceByTokenId[tokenId])
    return result
}

export const getCardsPricesBatch = async (tokenIds: string[]) => {
    const url = "https://api.opensea.io/api/v1/assets"
    const params = `${tokenIds
        .map((tokenId) => `token_ids=${tokenId}`)
        .join("&")}&asset_contract_address=${PARALLEL_TOKEN_ADDRESS}&limit=${
        tokenIds.length
    }`
    const json = await (await fetch(`${url}?${params}`)).json()
    const prices: Record<string, { currency: Currency; value: number }> = {}
    const assets = json["assets"]
    assets.forEach((asset: any) => {
        const lastSale = asset["last_sale"]
        if (!!lastSale) {
            const currency = lastSale["payment_token"]["symbol"]
            if (["ETH", "WETH", "USDC"].includes(currency)) {
                prices[asset["token_id"]] = {
                    currency,
                    value: parseFloat(
                        formatUnits(
                            lastSale["total_price"],
                            currencyDecimals(currency)
                        )
                    )
                }
            }
        }
    })
    return prices
}

export const getCardsPrices = async (tokenIds: string[]) => {
    const cardsPrices: Record<string, { currency: Currency; value: number }> =
        {}
    const limiter = new RateLimiter({ tokensPerInterval: 1, interval: 1500 })
    while (tokenIds.length > 0) {
        await limiter.removeTokens(1)
        const batch = tokenIds.splice(0, MAX_CARDS_PER_OPENSEA_REQUEST)
        const batchPrices = await getCardsPricesBatch(batch)
        Object.keys(batchPrices).forEach((tokenId) => {
            cardsPrices[tokenId] = batchPrices[tokenId]
        })
    }
    return cardsPrices
}

export const priceUsd = (
    price: { currency: Currency; value: number },
    ethInUsd: number | undefined
) => {
    switch (price.currency) {
        case "ETH":
        case "WETH":
            return price.value
        case "USDC":
            return typeof ethInUsd !== "undefined"
                ? price.value / ethInUsd
                : undefined
    }
}

export const currencyDecimals = (currency: Currency) => {
    switch (currency) {
        case "ETH":
        case "WETH":
            return 18
        case "USDC":
            return 6
    }
}
