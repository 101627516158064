import { FunctionComponent } from "react"
import { AiFillWarning } from "react-icons/ai"
import Modal from "react-modal"

import Button from "./Button"

const ErrorModal: FunctionComponent<{
    error: string
    isOpen: boolean
    onRequestClose: () => void
}> = ({ error, isOpen, onRequestClose }) => (
    <Modal
        isOpen={isOpen}
        onRequestClose={onRequestClose}
        contentLabel="Warning"
        overlayClassName="fixed inset-0 z-50 flex flex-col items-center justify-end md:justify-center bg-black bg-opacity-50 backdrop-filter backdrop-blur-md"
        className="w-full p-8 flex flex-col items-center max-w-lg gap-8 bg-parallel-200 text-white xl:border-b-2 rounded-tl-sm rounded-tr-sm xl:rounded-b-sm border-t-2 border-l-2 border-r-2 border-gray-900"
    >
        <div className="flex flex-col items-center">
            <AiFillWarning className="w-8 h-8 text-parallel-100" />
            <span className="text-xs uppercase font-bold text-parallel-100 font-inconsolata">
                Warning
            </span>
        </div>
        <span className="text-sm text-center font-inconsolata">{error}</span>
        <Button className="w-full" onClick={onRequestClose}>
            Ok
        </Button>
    </Modal>
)

export default ErrorModal
