import { providers } from "@0xsequence/multicall"
import classNames from "classnames"
import { ethers } from "ethers"
import { FunctionComponent, useState, useMemo } from "react"

import { useStoreActions, useStoreState } from "state/hooks"

import { cards } from "data/cards"
import { sets } from "data/sets"
import {
    getCardsBalances,
    getCardsBalancesInVault,
    getCachedParasets,
    getCachedCardsBalance
} from "data/utils"

import Button from "components/Button"
import ErrorModal from "components/ErrorModal"
import Input from "components/Input"
import SetCompletionView, {
    SetCompletion
} from "components/setchecker/SetCompletionView"

import { ADDRESS_INVALID_ERROR, NOT_AVAILABLE_ERROR } from "./errors"

const getAllCardsBalances = async (
    account: string
): Promise<Record<string, { wallet: number; vault: number }>> => {
    const provider = new providers.MulticallProvider(
        new ethers.providers.JsonRpcProvider(
            process.env.REACT_APP_JSON_RPC_ENDPOINT
        )
    )
    try {
        account = await provider.resolveName(account)
    } catch (e) {
        throw new Error(ADDRESS_INVALID_ERROR)
    }
    const cardsIds = Object.keys(cards)
    const walletCardsBalances = await getCardsBalances(
        account,
        [...cardsIds],
        provider
    )
    const vaultCardsBalances = await getCardsBalancesInVault(account, [
        ...cardsIds
    ])
    const balanceByCardId: Record<string, { wallet: number; vault: number }> =
        {}
    cardsIds.forEach(
        (cardId) => (balanceByCardId[cardId] = { wallet: 0, vault: 0 })
    )
    walletCardsBalances.forEach((balance, i) => {
        if (balance) balanceByCardId[cardsIds[i]].wallet = balance
    })
    vaultCardsBalances.forEach((balance, i) => {
        if (balance) balanceByCardId[cardsIds[i]].vault = balance
    })
    return balanceByCardId
}

const getCachedParasetBalances = async (account: string) => {
    const provider = new providers.MulticallProvider(
        new ethers.providers.JsonRpcProvider(
            process.env.REACT_APP_JSON_RPC_ENDPOINT
        )
    )
    try {
        account = await provider.resolveName(account)
    } catch (e) {
        throw new Error(ADDRESS_INVALID_ERROR)
    }

    const cachedParasets = await getCachedParasets(account, provider)
    const cachedCardsBalance = await getCachedCardsBalance(cachedParasets)
    return {
        cachedParasets: Object.fromEntries(
            cachedParasets.map((x) => [x.setName, x.amount])
        ),
        cachedParasetCardTotal: Object.values(cachedCardsBalance).reduce(
            (partialSum, a) => partialSum + a,
            0
        )
    }
}

const isENSDomain = (address: string) =>
    address.includes(".eth") && address.split(".eth")[0].length > 0

const ParaSetChecker: FunctionComponent<{}> = () => {
    const totalCardsBalances = useStoreState(
        (store) => store.totalCardsBalances
    )
    const cardsBalances = useStoreState((store) => store.cardsBalances)
    const [walletCards, vaultCards] = useMemo(() => {
        if (!cardsBalances) return [0, 0]
        return Object.values(cardsBalances).reduce(
            ([walletCards, vaultCards], { wallet, vault }) => [
                walletCards + wallet,
                vaultCards + vault
            ],
            [0, 0]
        )
    }, [cardsBalances])
    const setCardsBalances = useStoreActions(
        (actions) => actions.setCardsBalances
    )
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<string>()
    const [modalError, setModalError] = useState<string | undefined>()

    const [account, setAccount] = useState<string>()
    const [cachedParasets, setCachedParasets] = useState<{
        [fieldName: string]: number
    }>({})
    const [cachedParasetCardTotal, setCachedParasetCardTotal] = useState(0)

    const accountValid =
        isENSDomain(account || "") || ethers.utils.isAddress(account || "")

    const loadCardsBalances = async () => {
        if (!account) return
        setCardsBalances(undefined)
        setError(undefined)
        setLoading(true)
        try {
            setCardsBalances(await getAllCardsBalances(account))
            const { cachedParasets, cachedParasetCardTotal } =
                await getCachedParasetBalances(account)
            setCachedParasets(cachedParasets)
            setCachedParasetCardTotal(cachedParasetCardTotal)
        } catch (e) {
            console.error(e)
            const error = e as Error
            const message = error.message
            if (message === NOT_AVAILABLE_ERROR) {
                setError(undefined)
                setModalError(message)
            } else {
                setError(
                    message === ADDRESS_INVALID_ERROR
                        ? ADDRESS_INVALID_ERROR
                        : "An error has occurred. Please check again later."
                )
            }
        }
        setLoading(false)
    }

    const message = !!totalCardsBalances ? (
        <>
            {walletCards + vaultCards + cachedParasetCardTotal} total cards (
            {vaultCards} in{" "}
            <a
                href="https://parallel.life/faq/"
                className="underline"
                target="_blank"
                rel="noopener noreferrer"
            >
                Vault
            </a>
            , {cachedParasetCardTotal} cached). Completed sets:
        </>
    ) : (
        error || (loading ? "Loading..." : <>&nbsp;</>)
    )

    const setsCompletion = useMemo(() => {
        const result: Record<string, SetCompletion> = {}
        if (totalCardsBalances) {
            Object.keys(sets).forEach((setName) => {
                const set: Set<string> = (sets as any)[setName]
                const missingCardsCount = Array.from(set)
                    .map((tokenId) => totalCardsBalances[tokenId] > 0)
                    .map((b) => (b === true ? 0 : 1) as number)
                    .reduce((a, b) => a + b)
                const completed =
                    missingCardsCount > 0
                        ? 0
                        : Array.from(set)
                              .map((tokenId) => totalCardsBalances[tokenId])
                              .reduce((a, b) => Math.min(a, b))
                result[setName] = {
                    missingCardsCount,
                    completed
                }
            })
        }
        return result
    }, [totalCardsBalances])

    return (
        <>
            <div className="flex flex-col w-full">
                <div className="flex flex-row items-center w-full">
                    <Input
                        type="text"
                        label="Address"
                        containerClassName="flex-1"
                        placeholder="0x0000...0000"
                        onChange={(e) => setAccount((e.target as any).value)}
                    />
                    <Button
                        className="rounded-l-none"
                        onClick={loadCardsBalances}
                        disabled={!accountValid}
                    >
                        Check
                    </Button>
                </div>
                <span
                    className={classNames(
                        "text-parallel-100 text-center font-inconsolata mt-8 text-sm",
                        loading && "animate-pulse"
                    )}
                >
                    {message}
                </span>
                <div className="flex flex-col mt-8 space-y-4 text-sm">
                    {Object.keys(sets).map((setName, i) =>
                        !!setsCompletion[setName] ? (
                            <SetCompletionView
                                key={i}
                                i={i}
                                cached={cachedParasets[setName]}
                                setName={setName}
                                completion={setsCompletion[setName]}
                            />
                        ) : (
                            <span key={i}>&nbsp;</span>
                        )
                    )}
                </div>
            </div>
            <ErrorModal
                isOpen={!!modalError}
                error={modalError!}
                onRequestClose={() => setModalError(undefined)}
            />
        </>
    )
}

export default ParaSetChecker
